@import "~@flaticon/flaticon-uicons/css/all/all";

body {
  font-family: 'Arial', sans-serif;
  margin: 0 auto;
  padding: 0;
  background-color: #f4f4f4;
  overflow-x: hidden;
}
.home{
  max-width: 1080px;
  margin: 0 auto;
}

.header {
  background-color: white;
  color: #333;
  text-align: center;
  padding: 50px 20px;
}

.header .text {
justify-items: center;
}

.header .text button{
  background-color: #fec84e;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #333;
}

.video-container {
  display:none;
}

.header-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
}

.header h1 {
  font-size: 2.5em;
  margin: 0.5em 0;
}

.header p {
  font-size: 1.2em;
  margin: 0.5em 0;
}

section {
  padding: 20px;
  background-color: white;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.1);
}

section h2 {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

section ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
}

section ul li {
  background-color: #e7e7e7;
  display: flex;
  align-items: center;
  text-align: left;
  flex-direction: column;
  cursor: pointer;
}

section ul li img {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  border-radius: 5px;
}

section ul li i {
  font-size: 2em;
  margin-bottom: 10px;
  color: #fec84e;
}

section ul li h3 {
  font-size: 1.2em;
  margin: 0;
  color: #333;
}

section ul li p {
  margin: 0;
  color: #555;
}

section .packages ul li {
  flex-direction: column;
}

.packages ul.big_list li {
  background-color: white;
  font-weight: 600;
  color: #333;
  /* padding: 20px; */
}

.packages ul.big_list .basic {
  /* background-color: #c3cbdc;
  background-image: linear-gradient(147deg, #c3cbdc 0%, #edf1f4 74%); */
  border: 5px solid #c3cbdc;
  border-radius: 10px;
  box-shadow: 0 4px 6px #0000001a;
}

.packages ul.big_list .premium {
  /* background-color: #fff0cc;
  background-image: linear-gradient(315deg, #fff0cc 0%, #ffdea8 74%); */
  border: 5px solid #fff0cc;
  border-radius: 10px;
  box-shadow: 0 4px 6px #0000001a;
  transform: scale(1.1); /* Makes the premium package bigger */
  margin: 10px; /* Add some margin to space it out */
  margin-top: 2em;
  margin-bottom: 2.5em;
}

.packages ul.big_list .luxury {
  /* background-color: #fec84e;
  background-image: linear-gradient(315deg, #f1b52d 0%, #fbce83 74%); */
  border: 5px solid #fec84e;
  border-radius: 10px;
  box-shadow: 0 4px 6px #0000001a;
}

.package-details {
  display: none;
  list-style-type: none;
  padding: 10px;
  margin: 0;
  margin-top: 10px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  /* backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background: #fff;
    background: #fff3;
    border-radius: 10px;
    border-radius: 12px;
    box-shadow: 0 4px 6px #0000001a; */
}

.basic .package-name{
  background-color: #c3cbdc;
  background-image: linear-gradient(147deg, #c3cbdc 0%, #edf1f4 74%);
  color: #555;
}
.premium .package-name{
  background-color: #fff0cc;
  background-image: linear-gradient(315deg, #fff0cc 0%, #ffdea8 74%);
}
.luxury .package-name{
  background-color: #fec84e;
  background-image: linear-gradient(315deg, #f1b52d 0%, #fbce83 74%);
  color: #555;
}

.package-name{
font-size: 1.5em;
padding: 15px;
background-color: aliceblue;
width: 100%;
text-align: center;
border-top-right-radius: 5px;
border-top-left-radius: 5px;
}

ul.big_list ul.package-details li{
  /* margin: 5px 0; */
  background-color: transparent;
  /* border: 1px solid #333; */
  font-weight: 400;
  text-align: center;
  border-bottom: 1px solid #555;
  padding-top: 15px;
  padding-bottom: 15px;
}

ul.big_list ul.package-details li:last-child {
  border-bottom: none; /* Remove border-bottom for the last child */
}


.package-details.active {
  display: block;
}

.testimonials {
  text-align: center;
}

.testimonial {
  position: relative;
  display: inline-block;
  width: 80%;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonial p {
  font-style: italic;
  font-size: 1.2em;
  margin: 0 0 10px;
}

.progresss-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
}

.progresss{
  height: 100%;
  background-color: #fec84e;
  transition: width 0.15s linear;
}

.contact-info p, .our-story p {
  font-size: 1em;
  line-height: 1.6em;
}

.contact-info {
  background-color: #e7e7e7;
  padding: 20px;
  border-radius: 10px;
}

.our-story {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
}

section.services ul{
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

section.services ul li {
  background-color: white;
  box-shadow: 0 4px 8px #0000001a;
  border-radius: 10px;
  line-height: 1.5;
  height: 300px;
  box-sizing: border-box;
  margin: 10px;
  max-width: 220px; /* Adjust the width as needed */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.advantages {
  text-align: center;
  padding: 50px 20px;
}

.advantages ul {
  list-style-type: none;
  padding: 0;
}

.advantages li {
  display: inline-block;
  margin: 20px;
  vertical-align: top;
  width: 250px;
  background-color:white;
  box-shadow: 0 4px 8px #0000001a;
  border-radius: 10px;
  line-height: 1.5;
  height: 250px;
  text-align: center;
}

.advantages .gif-container {
  display: flex;
  justify-content: center;
  align-self: center;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
}

@media (min-width: 600px) {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 55vh;
    padding-bottom: 0;
  }
  .header .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 45%;
    padding: 10px;
    margin-right: 20px;
  }
  .header .video-container {
    clip-path: url('../Assets/blob.svg'); 
    clip-path: url(#blobClip); 
    display: block;
  }
  .header-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    overflow: visible;
  }
  .header .text h1 {
    font-size: 3em;
    text-align: left;
  }
  .header .text p {
    font-size: 1.5em;
    text-align: left;
  }
  section {
    padding: 40px 20px;
  }
  section ul {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1em;
  }
  .advantages ul li {
    width: 30%;
  }

  .services ul li {
    width: 25%;
  }
  .packages ul.big_list li {
    width: 33%;
  }
  .packages ul.package-details li {
    width: auto;
  }
  .packages ul.big_list {
    display: flex;
    align-items: baseline;
    margin-top: 2.5em;
  }
  .package-details {
    display: block !important; 
    height: 600px;
  }
  .contact-info p, .our-story p {
    font-size: 1.1em;
  }
}
