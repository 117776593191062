.admin{
    display: flex;
    flex-direction: column;
    padding: 2em;
}

.nav{
    max-width: 1000px;
    margin-left: 2em;
    padding: 1em;
    padding-bottom: 0px;
}
.nav .nav-link{
color: #ccc;
}
.tab-content form{
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-width: 800px;
    margin: 2em;
}

.tab-content form .item{
 border: 1px solid #ccc;
 padding: 10px;
 box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tab-content form button{
    padding: 10px;
    background-color: white;
    color: #333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    border: 1px solid #ccc;
}

.tab-content form button[type='submit']{
    background-color: #333;
    color: white;
}

.tab-content form .item button{
    background-color: crimson;
    color: white;
}

.tab-content form input{
    margin-right: 1em;
    width: 100%;
}

.tab-content form button{
    width: 30%;
}
