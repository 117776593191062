  form {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .contact{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .contact section{
    width: -webkit-fill-available;
  }

  label {
    font-size: 1em;
    margin-bottom: 5px;
    color: #333;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    font-size: 1em;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  textarea {
    height: 100px;
    resize: none;
  }
  
  button[type="submit"] {
    font-size: 1em;
    padding: 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #555;
  }
  
  .contact_text{
    margin-top: 2em;
    text-align: center;
  }
  @media (max-width: 600px) {
    section {
      margin: 10px;
      padding: 15px;
    }
    
    header h1 {
      font-size: 2em;
    }
  
    header p {
      font-size: 1em;
    }
  
    section h2 {
      font-size: 1.5em;
    }
  
    input[type="text"],
    input[type="email"],
    textarea {
      font-size: 0.9em;
    }
  
    button[type="submit"] {
      font-size: 0.9em;
    }
    .contact{
    flex-direction: column;
    }
  }
  