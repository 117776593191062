.navigation {
    color: #333;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    background-color: #fec84e;
    background-image: linear-gradient(315deg, #fec84e 0%, #ffdea8 74%);
  }
  
  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .nav-logo a {
    text-decoration: none;
    font-size: 1.5em;
  }
  
  .nav-logo img {
    height:50px;
  }

  .nav-menu {
    display: flex;
    align-items: center;
  }
  
  .nav-menu ul {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .nav-menu ul li {
    margin-right: 20px;
  }
  
  .nav-menu ul li a {
    text-decoration: none;
    font-size: 1.2em;
  }
  
  .nav-toggle {
    display: none;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
  }
  
  .hamburger {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 4px 0;
    transition: all 0.3s ease;
  }
  
  .navigation {
    background-color: #333;
    padding: 1em;
}

.navigation ul {
    list-style-type: none;
    padding: 0;
}

.navigation ul li {
    display: inline;
    margin-right: 1em;
}

.navigation ul li a {
    color: #333;
    text-decoration: none;
    font-weight: 300;
    padding: 5px 10px;
    border-radius: 5px;
}

.navigation ul li a:hover {
    background-color: #333;
    color: white;
}

  @media (max-width: 768px) {
    .navigation {
        display: block;
        justify-content: none;
    }
    .nav-logo img {
      height: auto;
      max-width: 80%;
    }  
    .nav-menu {
      display: none;
      flex-direction: column;
      width: 100%;
      text-align: center;
    }
  
    .nav-menu.open {
      display: flex;
    }
  
    .nav-toggle {
      display: block;
    }
  
    .nav-menu ul {
      flex-direction: column;
      gap: 10px;
    }
  
    .nav-menu ul li {
      margin: 10px 0;
    }
  
    .nav-toggle.open .hamburger:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }
  
    .nav-toggle.open .hamburger:nth-child(2) {
      opacity: 0;
    }
  
    .nav-toggle.open .hamburger:nth-child(3) {
      transform: rotate(-45deg) translate(5px, -5px);
    }
  }
  