:root {
    color-scheme: only light;
  }
  
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    font-family: 'Poppins', Arial, sans-serif;
}
body {
    font-family: 'Poppins', Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.header {
    background: #f4f4f4;
    padding: 2em;
    text-align: center;
}

.header img {
    max-width: 100%;
    height: auto;
}

section {
    padding: 2em;
}

footer {
    background: #333;
    color: white;
    text-align: center;
    padding: 1em;
}

footer ul {
    list-style-type: none;
    padding: 0;
}

footer ul li {
    display: inline;
    margin-right: 1em;
}

footer ul li a {
    color: white;
    text-decoration: none;
}
